import { useEffect, useRef } from 'react';
import './skills.css';
import { data } from '../data/skillsData';

const skillsInfo = {
  'HTML': {
    overview: 'Building the foundation of web pages with semantic HTML5',
    details: 'Creating accessible, semantic markup • Using modern HTML5 features • Implementing structured data • Ensuring valid document structure'
  },
  'User Research': {
    overview: 'Understanding user needs and behaviors through research',
    details: 'Conducting user interviews • Analyzing user behavior • Creating user personas • Usability testing • Gathering feedback'
  },
  'Accessibility': {
    overview: 'Making web content accessible to everyone',
    details: 'WCAG compliance testing • Screen reader optimization • Keyboard navigation • Color contrast checking • Writing aria labels'
  },
  'Inclusivity': {
    overview: 'Designing for diverse user needs and preferences',
    details: 'Cultural considerations • Language support • Flexible user interfaces • Adaptive technologies • Universal design principles'
  },
  'ReactJS': {
    overview: 'Building modern web applications with React',
    details: 'Component development • State management • Custom hooks • Performance optimization • React ecosystem tools'
  },
  'NextJS': {
    overview: 'Creating fast, SEO-friendly React applications',
    details: 'Server-side rendering • Static site generation • API routes • Image optimization • Dynamic routing'
  }
};

const Skills = () => {
  return (
    <section className="max-w-6xl mx-auto px-4 py-12">
      <div
        className="grid grid-cols-2 lg:grid-cols-3 gap-6 place-items-center"
        aria-label="Skills and experience"
      >
        {data.map((key) => (
          <div 
            key={key.id} 
            className="group relative p-4 rounded-lg hover:bg-gray-800/30 transition-colors w-full max-w-sm"
          >
            <div className="flex items-center justify-center text-center">
              <div>
                <h2 
                  className="text-xl font-medium group-hover:text-purple-400 transition-colors cursor-help"
                  tabIndex="0"
                  aria-describedby={`tooltip-${key.id}`}
                >
                  {key.skill}
                  <span 
                    className="ml-2 inline-flex items-center text-purple-400 opacity-0 group-hover:opacity-100 transition-opacity"
                    aria-hidden="true"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <circle cx="12" cy="12" r="10"></circle>
                      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                      <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                  </span>
                </h2>
                <p className="text-[#c4b8d7] mt-1">{key.Experience}</p>
              </div>
            </div>
            
            {skillsInfo[key.skill] && (
              <div
                id={`tooltip-${key.id}`}
                role="tooltip"
                className="invisible group-hover:visible group-focus-within:visible absolute z-10 w-72 p-4 bg-gray-900 rounded-lg shadow-lg -translate-y-full -translate-x-1/4 top-0 left-1/2 mt-2"
              >
                <p className="font-medium mb-2">{skillsInfo[key.skill].overview}</p>
                <p className="text-sm text-gray-300">{skillsInfo[key.skill].details}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
