import React, { createContext, useContext, useState } from 'react';

export const themes = {
  dark: {
    primary: '#121212',
    secondary: '#1a1a1a',
    accent: '#845ec2',
    text: '#ffffff',
  },
  royal: {
    primary: '#1C1C3A',
    secondary: '#2A2A4A',
    accent: '#B8860B',
    text: '#E5E5E5',
  },
  khaki: {
    primary: '#2F2F1E',
    secondary: '#3D3D2A',
    accent: '#9B8B6C',
    text: '#E6E6D6',
  }
};

const ThemeContext = createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState('dark');

  const value = {
    theme,
    setTheme,
    currentTheme: themes[theme]
  };

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
}