import './home.css';
// import Changer from './Changer';

const Home = () => {
  return (
    <main className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col-reverse md:flex-row items-center gap-8 md:gap-x-16 py-12">
        {/* Image Section */}
        <div className="w-full md:w-1/3 aspect-[3/4] relative order-2 md:order-1 px-6 pb-16 -mt-6">
          <div className="w-full h-full rounded-2xl overflow-hidden mb-6">
            <img
              src={require('../assets/bryantemple.png')}
              alt="Bryan- A Nigerian male accessibility developer wearing glasses with short beard, looking sidewards to the right"
              className="w-auto h-auto object-cover object-center rounded-2xl grayscale hover:grayscale-0 transition-all duration-400 mb-6"
              loading="eager"
              width={600}
              height={700}
            />
          </div>
        </div>

        {/* Content Section */}
        <div className="w-full md:w-2/3 order-1 md:order-2 space-y-6">
          <div className="text-center ">
            <h1 className="text-4xl md:text-7xl font-bold">
              Nice to e-meet You! I'm
              <span className="border-b-2 border-[#845ec2] pb-2 ml-4">Bryan</span>
            </h1>
          </div>
<div className='flex justify-center'>
          <p className="text-lg md:text-xl text-gray-300 max-w-lg py-6 text-center align-text-center px-1">
            Based in Nigeria 🌍 I'm a...
            {[
              "Web Developer ⚡",
              "Digital Accessibility Auditor 🔍",
              "Accessibility Advocate 💪",
              "Clip Cutter ✂️",
              "Contrast Checker 👀 "
            ].join(" • ")}
            nCertified Professional in Accessibility Core Competencies, passionate
            about making the digital space accessible for everyone. With a
            minimalist approach and microscopic eye for detail.
            <span 
              className="group relative cursor-help ml-1 inline-flex items-center border-b-2 border-dotted border-[#845ec2]"
              tabIndex="0"
              role="button"
              aria-describedby="wcag-tooltip"
            >
              WCAG(AAA) enthusiast
              <span className="ml-1 text-[#845ec2]" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="10"></circle>
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                  <line x1="12" y1="17" x2="12.01" y2="17"></line>
                </svg>
              </span>
              <span
                id="wcag-tooltip"
                role="tooltip"
                className="invisible group-hover:visible group-focus:visible absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-3 py-2 text-sm text-white bg-gray-900 rounded-lg shadow-lg w-64 z-10 before:content-[''] before:absolute before:top-full before:left-1/2 before:-translate-x-1/2 before:border-8 before:border-x-transparent before:border-b-transparent before:border-t-gray-900"
              >
                Web Content Accessibility Guidelines (WCAG) AAA is the highest level of web accessibility standards, ensuring digital content is accessible to everyone, including people with disabilities.
              </span>
            </span>
          </p>
          </div>

          <div className="flex justify-center ">
            <a
              href="#contact"
              className="inline-flex items-center px-6 py-3 text-[14px] font-medium tracking-wider
                        uppercase transition-all duration-200 border-2 border-transparent
                        hover:border-[#c5501e] hover:text-gray-100
                        border-[#845ec2] focus:bg-opacity-25
                        disabled:border-[#845ec2]
                        
                        text-white rounded-[10px]"
              role="button"
            >
              Let's connect
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;
