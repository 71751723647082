import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai';
import {  SiTwitter } from 'react-icons/si';
import './navbar.css';
import img from '../assets/Braye Logo (White).png';

const Navbar = () => {
  return (
    <header className="w-full py-6 pb-10 border-b border-gray-800">
      <nav 
        className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8" 
        role="navigation"
        aria-label="Main navigation"
      >
        <div className="flex flex-col md:flex-row items-center justify-between gap-6">
          {/* Logo Container */}
          <div className="w-[180px] md:w-[220px] flex justify-center">
            <img 
              src={img} 
              height={120} 
              width={200} 
              alt="Bryan Temple's portfolio logo"
              className="w-full h-auto object-contain"
              priority="true"
            />
          </div>
          
          {/* Navigation Links */}
          <ul 
            className="flex items-center gap-8"

            aria-label="Social media links"
          >
            {[
            { 
              Icon: AiFillLinkedin, 
              href: "https://www.linkedin.com/in/bryanonyenghan", 
              label: "Connect with me on LinkedIn",
              name: "LinkedIn"
            },
              { 
                Icon: AiFillGithub, 
                href: "https://github.com/obraye1", 
                label: "Visit my GitHub profile",
                name: "GitHub"
              },
             
             
              { 
                Icon: SiTwitter, 
                href: "https://twitter.com/Mraccessibility", 
                label: "Follow me on X (formerly Twitter)",
                name: "X"
              }
            ].map(({ Icon, href, label, name }) => (
              <li key={href}>
                <a 
                  href={href}
                  aria-label={label}
                  className="group inline-flex items-center transition-transform hover:scale-110 focus:scale-110 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 rounded-lg p-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon 
                    size={38} 
                    className="text-gray-300 group-hover:text-purple-500 transition-colors"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{name}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;

