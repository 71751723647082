import { lazy, Suspense } from 'react';
// import { HelmetProvider } from 'react-helmet-async';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Skills from './components/Skills';
import Footer from './components/Footer';
import './index.css';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from "@vercel/analytics/react";
import { ThemeProvider } from './components/ThemeContext';
import './hr.css'

// Simple loading fallback
const LoadingFallback = () => (
  <div className="flex justify-center items-center min-h-[200px]">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

// Lazy load components
const Projects = lazy(() => import('./components/Projects'));
const Contact = lazy(() => import('./components/Contact'));

function App() {
  return (
    <ThemeProvider>
      <main className="bg-[rgb(18,18,18)] w-screen">
        <SpeedInsights />
        <Analytics />
        <div className="max-w-7xl mx-auto ">
            <hr 
              aria-hidden="true" 
              className="border-t border-gray-700/50"

            />
          </div>
        <Navbar />
        
        <Home />
        <div className="max-w-6xl mx-auto py-16">
            <hr 
              aria-hidden="true" 
              className="border-t border-gray-700/50"
            />
          </div>
        <Skills />
        <div className="max-w-6xl mx-auto py-16">
            <hr 
              aria-hidden="true" 
              className="border-t border-gray-700/50"
            />
          </div>
        <Suspense fallback={<LoadingFallback />}>
          <Projects />
          <div className="max-w-6xl mx-auto py-16">
            <hr 
              aria-hidden="true" 
              className="border-t border-gray-700/50"
            />
          </div>
          <Contact />
        </Suspense>
          <div className="max-w-6xl mx-auto py-16">
            <hr 
              aria-hidden="true" 
              className="border-t border-gray-700/50"
            />
          </div>
        <Footer />
      </main>
    </ThemeProvider>
  );
}

export default App;

  