import img1 from '../assets/Project-dashboard.png';
import img2 from '../assets/project55.png';
import img3 from '../assets/project3.png';
import img4 from '../assets/project4.png';
import img5 from '../assets/img5.jpg';
import img6 from '../assets/project77.png';

export const projects = [
  {
    id: 1,
    img: img1,
    title: 'Responsive Dashboard',
    description: 'A modern, fully responsive admin dashboard built with real-time data visualization and user analytics. Features include customizable widgets, dark/light mode, and interactive charts for business metrics.',
    tech: 'HTML',
    styling: 'CSS',
    framework: 'Nextjs',
    web: 'https://dashboad-kohl.vercel.app/',
    code: 'https://github.com/obraye1/dashboad',
  },
  {
    id: 2,
    img: img2,
    title: 'Weez-kitchen',
    description: 'An innovative restaurant management system featuring online ordering, menu customization, and inventory tracking. Includes real-time order updates and integration with popular delivery services.',
    tech: 'HTML',
    styling: 'TailWindCSS',
    framework: 'Nextjs',
    web: 'https://weezkitchen.vercel.app/',
    code: 'https://github.com/obraye1/weezkitchen',
  },
  {
    id: 3,
    img: img3,
    title: 'Weather Forecast',
    description: 'A TypeScript-based weather application providing accurate forecasts with hourly and weekly predictions. Features include interactive maps, severe weather alerts, and location-based recommendations.',
    tech: 'TYPESCRIPT',
    styling: 'Tailwind',
    framework: 'ReactJS',
    web: 'https://cityweatherapp.vercel.app',
    code: 'https://github.com/obraye1/ToDo',
  },
  {
    id: 4,
    img: img4,
    title: 'Lawgrid Project',
    description: 'A collaborative platform for legal professionals featuring document management, case tracking, and deadline reminders. Built with focus on security and confidentiality.',
    tech: 'HTML',
    styling: 'Tailwind',
    framework: 'NextJS',
    web: 'https://lawgrid.vercel.app/',
    code: '/',
  },
  {
    id: 5,
    img: img5,
    title: 'Group Assessment Project',
    description: 'A comprehensive healthcare management system designed for families. Features include appointment scheduling, medical record tracking, and medication reminders with a focus on user privacy.',
    tech: 'HTML',
    styling: 'TailwindCSS',
    framework: 'ReactJS',
    web: 'https://fam-health.vercel.app',
    code: 'https://github.com/obraye1/famHealth',
  },
  {
    id: 6,
    img: img6,
    title: 'Flight Data Rendering',
    description: 'Real-time flight tracking application with interactive maps, price comparisons, and delay predictions. Includes features for booking management and travel planning. -not completed ',
    tech: 'HTML',
    styling: 'TailwindCSS',
    framework: 'Reactjs',
    web: 'https://britfli.vercel.app/',
    code: 'https://github.com/obraye1/flightReactJS',
  },
];

const experienceStartDates = {
  HTML: new Date(2018, 8, 1),
  UX: new Date(2021, 0, 1),
  Accesibility: new Date(2022, 0, 1),
  Inclusivity: new Date(2014, 0, 1),
  ReactJS: new Date(2020, 0, 1),
  NextJs: new Date(2021, 0, 1),
};

// function getYearsExperience(startDate) {
//   const currentDate = new Date();
//   const yearsDifference = currentDate.getFullYear() - startDate.getFullYear();
//   const monthsDifference = currentDate.getMonth() - startDate.getMonth();
  
//   // Adjust years based on months
//   let years = yearsDifference;
//   if (monthsDifference < 0) {
//     years--;
//   }
  
//   // Handle case for less than a year
//   if (years === 0) {
//     const months = monthsDifference >= 0 ? monthsDifference : 12 + monthsDifference;
//     return months === 1 ? '1 Month Experience' : `${months} Months Experience`;
//   }
  
//   // Return years
//   return years === 1 ? '1 Year Experience' : `${years} Years Experience`;
// }

export const data = [
  {
    id: 1,
    skill: "HTML",
    Experience: "5+ Years Experience"
  },
  {
    id: 2,
    skill: "User Research",
    Experience: "3+ Years Experience"
  },
  {
    id: 3,
    skill: "Accessibility",
    Experience: "2+ Years Experience"
  },
  {
    id: 4,
    skill: "Inclusivity",
    Experience: "2+ Years Experience"
  },
  {
    id: 5,
    skill: "ReactJS",
    Experience: "4+ Years Experience"
  },
  {
    id: 6,
    skill: "NextJS",
    Experience: "2+ Years Experience"
  }
];